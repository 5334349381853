import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircle,
  faLaptop,
  faMobile,
  faCogs,
  faPuzzlePiece,
  faCloud,
  faEdit,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Services = ({ data, location }) => {
  return (
    <Layout location={location} title="{siteTitle}">
      <SEO title="Services" />
      <div className="pageContent">
        <article className="page__wrapper">
          <header className="page__header">
            <h1 className="page__title">Services</h1>
            <p className="page__intro">
              We create &amp; produce software and mobile solutions that solve
              users' problems.
            </p>
          </header>

          <ul id="services">
            <li>
              <span className="fa-6x">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <FontAwesomeIcon
                    icon={faLaptop}
                    inverse
                    transform="shrink-8"
                  />
                </span>
              </span>
              <h4 className="service-heading">
                Software
                <br />
                Development
              </h4>
            </li>
            <li>
              <span className="fa-6x">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <FontAwesomeIcon
                    icon={faMobile}
                    inverse
                    transform="shrink-8"
                  />
                </span>
              </span>
              <h4 className="service-heading">
                Mobile App
                <br />
                Development
              </h4>
            </li>
            <li>
              <span className="fa-6x">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <FontAwesomeIcon icon={faCogs} inverse transform="shrink-8" />
                </span>
              </span>
              <h4 className="service-heading">
                Prototyping
                <br />
                <br />
              </h4>
            </li>
            <li>
              <span className="fa-6x">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <FontAwesomeIcon
                    icon={faPuzzlePiece}
                    inverse
                    transform="shrink-8"
                  />
                </span>
              </span>
              <h4 className="service-heading">
                Software
                <br />
                Integration
              </h4>
            </li>
            <li>
              <span className="fa-6x">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <FontAwesomeIcon
                    icon={faCloud}
                    inverse
                    transform="shrink-8"
                  />
                </span>
              </span>
              <h4 className="service-heading">
                Azure
                <br />
                Architecture
              </h4>
            </li>
            <li>
              <span className="fa-6x">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <FontAwesomeIcon icon={faEdit} inverse transform="shrink-8" />
                </span>
              </span>
              <h4 className="service-heading">
                CMS
                <br />
                Development
              </h4>
            </li>
          </ul>

          <section className="postPage__content"></section>
        </article>
      </div>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
